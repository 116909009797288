<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-edutrain-dashboard">
    <lxp-main-header :show-title="true" :show-breadcrumb="true" :show-excel-download="true">
      <template v-slot:action>
        <HistoryYearSelector :year-cnt="6" :show-select-all="false" v-model="selectedYear" />
      </template>
    </lxp-main-header>
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section:2022년 활동지표 요약 -->
      <!-- <HistoryDashboardYearSummary :selected-year="selectedYear"/>-->
      <section class="content-section section-summary">
        <header class="section-header">
          <h4 class="title">{{selectedYear}}년 활동지표 요약</h4>
        </header>
        <div class="barlink-wrap">
          <ul class="barlink-list dashboard-summary-wrap">
            <li v-for="(item, idx) in items" :key="idx" class="barlink-item"
                :class="idx > 1 ? 'dashboard-summary-not-top-item':''"
                style="margin-top: 0;">
              <router-link :to="goToPage(item.crseToIdStr)" class="barlink">
                <i v-if="item.crseToIdStr === 'hrdcloud'" class="icon-symbol-hrdcloud"></i>
                <i v-if="item.crseToIdStr === 'regular'" class="icon-symbol-regular"></i>
                <i v-if="item.crseToIdStr === 'certificate' || item.crseToIdStr === 'job-certification'" class="icon-symbol-certificate"></i>
                <i v-if="item.crseToIdStr === 'eTest'" class="icon-symbol-eTest"></i>
                <i v-if="item.crseToIdStr === 'knowe' || item.crseToIdStr === 'group' || item.crseToIdStr === 'cloudeasy'" class="icon-symbol-prep"></i>
                <span class="title">{{item.crseToNm}}</span>
                <span class="arrow">{{item.cnt}}개<i class="icon-arrow"></i></span>
              </router-link>
            </li>
          </ul>
        </div>
      </section>

      <!-- content-section:직무인증 이력 -->
      <HistoryDashboardCertiHistory/>

      <!-- content-section:2022년 월별 완료/전체연수 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">2022년 월별 완료/전체연수</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
<!--          <div class="chart chart-bar">-->
<!--            <img src="../../../assets/lxp/images/@tmp/@tmp_chartA_01.png" alt="임시이미지" width="688" />-->
<!--          </div>-->
          <ToastColumnChart :categories="lrnCategories" :series="lrnSeries"/>
          <!-- chart-details -->
          <div class="chart-details">
            <div class="detail-top">
              <div class="detail-column">
                <div class="chart-numerical">+3</div>
                <div class="chart-subtitle">2021년 대비 총 3개 상승</div>
              </div>
              <div class="detail-column">
                <div class="chart-numerical">17%</div>
                <div class="chart-subtitle">동일직급(L2) 중 상위 17%</div>
              </div>
            </div>
            <div class="detail-bottom">
              <div class="chart-labels">
                <div class="label"><i class="label-mark mark-primary"></i><span class="label-text">완료연수</span></div>
                <div class="label"><i class="label-mark mark-secondary"></i><span class="label-text">전체연수</span></div>
              </div>
            </div>
          </div>
        </div>
        <!-- //chart-wrap -->
      </section>
      <!-- content-section:2022년 월별 획득 마일리지 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">2022년 월별 획득 마일리지</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
<!--          <div class="chart chart-line">-->
<!--            <img src="../../../assets/lxp/images/@tmp/@tmp_chartA_02.png" alt="임시이미지" width="654" />-->
<!--          </div>-->
          <ToastLineChart />
          <!-- chart-details -->
          <div class="chart-details">
            <div class="detail-top">
              <div class="detail-column">
                <div class="chart-numerical">-1</div>
                <div class="chart-subtitle">2021년 대비 총 1점 하락</div>
              </div>
              <div class="detail-column">
                <div class="chart-numerical">78%</div>
                <div class="chart-subtitle">동일 직급(L2) 중 상위 78%</div>
              </div>
            </div>
          </div>
        </div>
        <!-- //chart-wrap -->
      </section>
      <!-- content-section:2022년 월별 방문일수 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">2022년 월별 방문일수</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
<!--          <div class="chart chart-bar">-->
<!--            <img src="../../../assets/lxp/images/@tmp/@tmp_chartA_03.png" alt="임시이미지" width="654" />-->
<!--          </div>-->
          <ToastColumnChart :categories="visitCategories" :series="visitSeries" />
          <!-- chart-details -->
          <div class="chart-details">
            <div class="detail-top">
              <div class="detail-column">
                <div class="chart-numerical">+20</div>
                <div class="chart-subtitle">2021년 대비 총 20회 상승</div>
              </div>
              <div class="detail-column">
                <div class="chart-numerical">1%</div>
                <div class="chart-subtitle">동일 직급(L2) 중 상위 1%</div>
              </div>
            </div>
          </div>
        </div>
        <!-- //chart-wrap -->
      </section>
      <!-- content-section:2022년 가장 오래 학습한 HRD 클라우드 콘텐츠 TOP5 -->
      <section class="content-section section-chart section-divider">
        <header class="section-header">
          <h4 class="title">2022년 가장 오래 학습한 HRD 클라우드 콘텐츠 TOP5</h4>
        </header>
        <!-- chart-wrap -->
        <div class="chart-wrap">
          <!-- chart -->
<!--          <div class="chart chart-pie">-->
<!--            <img src="../../../assets/lxp/images/@tmp/@tmp_chartA_04.png" alt="임시이미지" width="301" />-->
<!--          </div>-->
          <ToastPieChart />
          <!-- chart-details -->
          <div class="chart-details">
            <div class="chart-rank">
              <div class="kb-table kb-table-striped">
                <table>
                  <colgroup>
                    <col style="width:45px;" />
                    <col style="width:auto;" />
                    <col style="width:auto;" />
                  </colgroup>
                  <tbody>
                  <tr>
                    <th><strong class="th-title text-primary rank">1</strong></th>
                    <td><strong class="td-text">가계여신 알아보기</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">49분 09초</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">2</strong></th>
                    <td><strong class="td-text">자산관리컨설팅하기</strong></td>
                    <td class="text-end"><strong class="td-text text-muted"> 42분 17초</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">3</strong></th>
                    <td><strong class="td-text">WM전략본부</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">33분 33초</strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">4</strong></th>
                    <td><strong class="td-text">전세자금대출 문의사항</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">10분 19초 </strong></td>
                  </tr>
                  <tr>
                    <th><strong class="th-title rank">5</strong></th>
                    <td><strong class="td-text">WM투자전략 화상회의</strong></td>
                    <td class="text-end"><strong class="td-text text-muted">02분 47초</strong></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- //chart-wrap -->
      </section>
    <!-- //main-content -->
    </div>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import {ref, watch} from 'vue';
import {getItems, getLastCntYears, lengthCheck} from '@/assets/js/util';
import HistoryYearSelector from '@/components/history/HistoryYearSelector';
import HistoryDashboardCertiHistory from '@/components/history/HistoryDashboardCertiHistory';
import ToastColumnChart from '@/components/chart/ToastColumnChart';
import ToastLineChart from '@/components/chart/ToastLineChart';
import ToastPieChart from '@/components/chart/ToastPieChart';
import {useStore} from 'vuex';
import {ACT_GET_YEAR_SUMMARY_LIST} from '@/store/modules/history/history';

export default {
  name: 'HistoryDashboard',
  components: {
    ToastPieChart,
    ToastLineChart,
    ToastColumnChart,
    HistoryDashboardCertiHistory, HistoryYearSelector, LxpMainHeader},
  setup() {
    const store = useStore();
    const years = ref(getLastCntYears(6));
    const selectedYear = ref(new Date().getFullYear());

    const items = ref([]);

    const lrnCategories = ref(['1월','2월','3월','4월','5월']);
    const lrnSeries = ref([{
      name: '완료연수',
      data: [20, 30, 50, 30, 50, 20, 10]
    },
    {
      name: '전체연수',
      data: [80, 40, 70, 50, 60, 30, 50]
    }]);
    const visitCategories = ref(['1월','2월','3월','4월','5월']);
    const visitSeries = ref([{
      name: '완료연수',
      data: [20, 30, 50, 30, 50, 20, 10]
    }]);

    const getYearSummaryList = () => {
      store.dispatch(`history/${ACT_GET_YEAR_SUMMARY_LIST}`, {
        yearStr: selectedYear.value
      }).then(res => {
        if(lengthCheck(res)){
          items.value = getItems(res);
        }else{
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    };

    const goToPage = (crseToIdStr) => {
      let routerTo = {query: {'year': selectedYear.value}};
      if (crseToIdStr === 'regular') {
        routerTo.name = 'HistoryRegular';
      } else if (crseToIdStr === 'certificate') {
        routerTo.name = 'HistoryCertificate';
      } else if (crseToIdStr === 'eTest') {
        routerTo.name = 'HistoryEtest';
      } else if (crseToIdStr === 'hrdcloud') {
        routerTo.name = 'HistoryHrdCloud';
      } else if (crseToIdStr === 'job-certification') {
        routerTo.name = 'HistoryJobCertification';
      } else if (crseToIdStr === 'knowe') {
        routerTo.name = 'HistoryKnowledgeE';
      } else if (crseToIdStr === 'group') {
        routerTo.name = 'HistoryGroupLearning';
      } else if (crseToIdStr === 'cloudeasy') {
        routerTo.name = 'HistoryCloudEasy';
      }
      return routerTo;
    }

    getYearSummaryList();

    watch(() => selectedYear.value, () => {
      getYearSummaryList();
    });

    return {
      years,
      selectedYear,
      items,

      goToPage,

      lrnCategories,
      lrnSeries,
      visitCategories,
      visitSeries
    }
  }
};
</script>


<style scoped>
.dashboard-summary-wrap{
  display: inline-flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.barlink-item{
  width: 590px;
}
.dashboard-summary-not-top-item{
  margin-top: var(--kb-spacer-4) !important;
}
</style>